/**
 * AccordionDetailsTable
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class AccordionDetailsTable
 * @param {object} props - Props
 */

import React from "react";
import Icon from "../../atoms/icon";
import {Link} from "../../atoms/link";

const AccordionDetailsTable = ({headers = [], items = []}) => {

    return (
        <div className="accordion-table">
            <div className="accordion-table__headers">
                {headers.length > 0 && headers.map(({label, empty}, idx) => (
                    <span key={idx}>{!empty && label}</span>
                ))}
            </div>
            {items.map((item, idx) => (
                <div key={idx} className="accordion-table__row">
                    {headers.map(({label}, idx) => {
                        const itemFound = item.find(v => v.field === label);

                        if (!itemFound){
                            return null //Array.prototype.map() expects a return value from arrow function
                        }
                        const {value, icon, link} = itemFound;

                        return (
                            <span key={idx}>
                                {value && item.find(v => v.field === label)?.value}
                                {(link && <Link to={link} target="_blank" download>
                                    {value && item.find(v => v.field === label)?.value}
                                    {icon && <Icon
                                        className="storybook"
                                        color="blue"
                                        name={icon}
                                    />}
                                </Link>)}
                            </span>
                        )
                    })}
                </div>
            ))}
        </div>
    );
}

export default AccordionDetailsTable;
