/**
 * AccordionDetailsCompare
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class AccordionDetailsCompare
 * @param {object} props - Props
 */

import React from "react";

const AccordionDetailsCompare = ({categories, products}) => {

    return (
        <div className="accordion-compare">
            {categories.length > 0 && categories.map(({label, id}, idx) => (
                <div key={idx} className="accordion-compare__row">
                    <div className="accordion-compare__row-top">{label}</div>
                    <div className="accordion-compare__row-down">
                        {products.map((item, idx) => (
                            <span key={idx} className="accordion-compare__row-down__item">{item.find(v => v.propertyId === id)?.value || '-'}</span>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AccordionDetailsCompare;
