/**
 * CompareCardContainer
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CompareCardContainer
 * @param {object} props - Props
 */

import React, {useEffect, useRef, useState} from "react";
import CompareCard from "../../components/compare-card";
import {Link} from "../../atoms/link";
import Icon from "../../atoms/icon";

const CompareCardContainer = ({products = []}) => {

    const [isSticky, setIsSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            const {top, height} = ref.current.getBoundingClientRect();
            const footer = document.querySelector('footer').getBoundingClientRect();

            setIsSticky(top <= -(height - (height * 0.4)) && footer?.top > height);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return window.removeEventListener('scroll', () => handleScroll)
    }, []);

    return (
        <>
            <div ref={ref} className={`compare-card-container ${isSticky ? 'compare-card-container--sticky' : ''}`}>
                {products.map((item, idx) =>
                    idx <= 4 ? <CompareCard key={idx} product={item}/> : null
                )}
                <div className="container compare-card-container__sticky-container">
                    {products.map((item, idx) => idx <= 4 ? (
                        <div key={idx} className="compare-card-container__sticky-item">
                            <div
                                className="compare-card-container__sticky-family">{item.familiesIds.map(item => item.title).join(', ')}</div>
                            <div className="compare-card-container__sticky-name">{item.productNameVerbose}</div>
                            <div className="compare-card-container__sticky-model">{item.productName}</div>
                        </div>
                    ) : null)}
                </div>
            </div>
            {products.length < 4 &&
                <div className="compare-card-container__add-item">
                    <Link>
                        <Icon
                            className="storybook"
                            color="blue"
                            name="symbols-plus"
                        />
                        <span className="text">Aggiungi un altro prodotto</span>
                    </Link>
                </div>
            }
        </>
    );
}

export default CompareCardContainer;
