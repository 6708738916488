/**
 * AccordionDetailsDescriptionList
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class AccordionDetailsDescriptionList
 * @param {object} props - Props
 */

import React from "react";

const AccordionDetailsDescriptionList = ({categories = [], product = []}) => {

    return (
        <dl className="accordion-description-list">
            {categories.length > 0 && categories.map(({label, id}, idx) => (
                <div key={idx} className="accordion-description-list__row">
                    <dt className="accordion-description-list__title">{label}</dt>
                    <dd className="accordion-description-list__description">{product.find(v => v.propertyId === id)?.value || '-'}</dd>
                </div>
            ))}
        </dl>
    );
}

export default AccordionDetailsDescriptionList;
