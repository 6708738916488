/**
 * CompareCard
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CompareCard
 * @param {object} props - Props
 */

import React from "react";
import {Link} from "../../atoms/link";
import Icon from "../../atoms/icon";
import Button from "../../atoms/button";
import ReevooBadge from "../reevoo/reevoo-badge";

const CompareCard = (
    {
        product: {
            familiesIds,
            price,
            primaryimage,
            productName: model,
            productNameVerbose: name,
            key_prod: productKey,

            allPurchaseOptionsButton,
            goToProductDetailButton,
        },
        onClose: onCloseCallback,

        ...props
    }) => {

    const handleClose = event => {
        event.preventDefault();
        onCloseCallback && onCloseCallback(event);
    };

    return (
        <>
            <div className="compare-card" {...props}>
                <div className="compare-card__header">
                    {productKey && <ReevooBadge productId={productKey}/>}
                    <Link className="compare-card__close" to="#" onClick={handleClose}>
                        <Icon color="white" name="symbols-plus"/>
                    </Link>
                </div>
                <div className="compare-card__image-container">
                    <img
                        className="compare-card__image"
                        src={primaryimage.find(item => item?.imageType === "S")?.imageUrl}
                        alt={name + ' image'}
                    />
                </div>
                <div className="compare-card__body">
                    <div className="compare-card__family">{familiesIds.map(item => item.title).join(', ')}</div>
                    <div className="compare-card__name">{name}</div>
                    <div className="compare-card__model">{model}</div>
                </div>
                <div className="compare-card__footer">
                    <div className="compare-card__change-model">
                        <Link>
                            <Icon color="blue" name="symbols-change"/>
                            Cambia modello
                        </Link>
                    </div>
                    {price && (
                        <div className="compare-card__price">
                            {price?.old && <span className="old">{price.old}</span>}
                            <span className="current">{price?.current}</span>
                        </div>
                    )}
                    <div className="compare-card__action compare-card__action--primary">
                        {price?.storeCta && (
                            <>
                                <Button
                                    className="primary"
                                    color="blue"
                                    href={price.storeLink}
                                    label={price?.storeCta}
                                />
                                {/*<Button
                                    className="secondary"
                                    color="white"
                                    href={price.storeLink}
                                    label="Acquista"
                                />*/}
                            </>
                        )}
                    </div>
                    {allPurchaseOptionsButton && <div className="compare-card__action compare-card__action--secondary">
                        <Button
                            className="secondary"
                            color="white"
                            href={allPurchaseOptionsButton?.link}
                            label={allPurchaseOptionsButton?.label}
                        />
                    </div>}
                    {goToProductDetailButton && <div className="compare-card__action compare-card__action--link">
                        <Button
                            className="arrow _15"
                            color="blue"
                            type="arrow"
                            href={goToProductDetailButton.link}
                            label={goToProductDetailButton.label}
                        />
                    </div>}
                </div>
            </div>
        </>
    );
}

export default CompareCard;
