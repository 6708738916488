import React from "react"

import Layout from "../app/layouts/default"
import { default as Accordion } from "../app/components/custom-accordion";
import AccordionDetailsCompare from "../app/components/accordion-details-compare";
import AccordionDetailsDescriptionList from "../app/components/accordion-details-description-list";
import AccordionDetailsTable from "../app/components/accordion-details-table";
import { graphql, useStaticQuery } from "gatsby";
import CompareCardContainer from "../app/modules/compare-card-container";
import Helpers from "../app/helpers";

const ComparePage = () => {

    const rawData = useStaticQuery(graphql`
    {
        allBabelProdotto(
            filter: {familiesIds: {}, primaryimage: {elemMatch: {imageType: {}}}, lineId: {eq: 777}}
        ) {
            edges {
                node {
                    attributes {
                        category {
                            nameCategory
                            id
                        }
                        fieldName
                        fieldValue
                        id
                    }
                    lineId
                    key_prod
                    id
                    familiesIds {
                        title
                        id
                        triples {
                            id
                            image
                            linkcall
                            linkimg
                            market_exception
                            text
                            titimg
                            title
                            video
                        }
                    }
                    brand_id
                    brandName
                    productName
                    primaryimage {
                        imageType
                        imageUrl
                    }
                    internal {
                        description
                        ignoreType
                        mediaType
                        content
                    }
                    triples {
                        id
                        image
                        linkcall
                        linkimg
                        market_exception
                        text
                        titimg
                        title
                        video
                    }
                    connectivity
                }
            }
        }
    }
    `)
    const rawAttributes = rawData.allBabelProdotto.edges.map(({ node: { attributes } }) => attributes);

    const attributes = rawAttributes
        .slice(0, 4)
        .map(product => product
            .filter(property => property.category.id === 96)
            .map(property => ({
                property: property.fieldName,
                propertyId: property.id,
                value: property.fieldValue
            })));
    const products = Helpers.productsDataManipulation(rawData.allBabelProdotto.edges).map((item, idx) => {
        item.node.allPurchaseOptionsButton = {
            link: 'https://example.com',
            label: 'Tutte le opzioni di acquisto'
        };
        item.node.goToProductDetailButton = {
            link: '/pdp',
            label: 'Vai alla scheda prodotto'
        };
        item.attributes = attributes[idx];
        return item?.node;
    });

    const categories = attributes.reduce((end, current) => {
        const newValues = current.reduce((end, current) => {
            const label = current.property;
            if (!end.find(item => item?.id === current.propertyId)) {
                end.push({
                    label,
                    id: current.propertyId
                });
            }
            return end;
        }, [])
            .filter(item => !end.find(i => i.id === item.id));
        return [...end, ...newValues];
    }, []);

    const accordionTableData = {
        headers: [{
            label: 'Modello'
        }, {
            label: 'Codice prodotto'
        }, {
            label: 'Lingua'
        }, {
            empty: true,
            label: 'Download'
        }],
        items: [
            [{
                field: 'Modello',
                value: 'AQUA 80F/1'
            }, {
                field: 'Codice prodotto',
                value: '31004081'
            }, {
                field: 'Lingua',
                value: 'IT'
            }, {
                field: 'Modello',
                value: 'AQUA 80F/1'
            }, {
                field: 'Codice prodotto',
                value: '31004081'
            }, {
                field: 'Lingua',
                value: 'IT'
            }, {
                field: 'Download',
                icon: 'symbols-download',
                link: 'http://www.africau.edu/images/default/sample.pdf'
            }]
        ]
    };

    return (
        <Layout noMarginFooter={true}>
            <div className="container">
                <CompareCardContainer products={products.slice(0, 4)} />
            </div>
            <div style={{ padding: '250px 0 40px', background: '#F6FCFE', marginTop: '-160px' }}>
                <section className="container">
                    {[1, 2, 3, 4, 5].map((i, idx) => (
                        <Accordion key={idx} title="Caratteristiche principali">
                            <AccordionDetailsCompare categories={categories} products={attributes.slice(0, 4)} />
                        </Accordion>
                    ))}
                    <Accordion title="Caratteristiche principali">
                        <AccordionDetailsDescriptionList categories={categories} product={attributes[0]} />
                    </Accordion>
                    {/* <Accordion title="Caratteristiche principali">
                        <AccordionDetailsTable headers={accordionTableData.headers} items={accordionTableData.items}/>
                    </Accordion> */}
                </section>
            </div>
        </Layout>
    )
};

export default ComparePage